import "@hotwired/turbo-rails";
//Turbo.session.drive = false;
Turbo.setProgressBarDelay(500);

import { turboConfirmationDialog } from "../mixins/confirmationDialog";
Turbo.setConfirmMethod((message, formElement) => {
  turboConfirmationDialog(formElement.cloneNode(true));
});

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

const application = Application.start();
// Loads all controllers
const context = require.context("../controllers", true, /\.js$/);
application.load(definitionsFromContext(context));
